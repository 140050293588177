<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      ads: {
        status: null,
        url: null,
      },

      total: {
        stakes: "R$ -,--",
        revshare: "R$ -,--",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      monthly: 'inactive',

      statData: null,
    };
  },
  methods: {
    getAds() {
      api
        .get("stakes/ads")
        .then((response) => {
          if (response.data.status == "success") {
            this.ads.status = response.data.ads.status
            this.ads.url = response.data.ads.url
          }
        });
    },
    getMonthly() {
      api
        .get('report/monthly')
        .then(response => {
          this.monthly = response.data.status
          this.getStatData();
        })
    },
    getTotalStakes() {
      api
        .get('stakes/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.stakes = this.$options.filters.currency(response.data.stakes)
            this.total.revshare = this.$options.filters.currency(response.data.revshare)
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api
        .get('report/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance/network')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Total de Stake",
          value: this.total.stakes
        },
        {
          title: "Total de Revshare",
          value: this.total.revshare
        },
        {
          title: "Total de Comissão",
          value: this.total.bonus
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance
        }
      ]
    },
  },
  mounted() {
    this.getAds()
    this.getMonthly()
    this.getTotalStakes()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div v-if="ads && ads.status && ads.url" class="card">
      <div class="ads card-body py-2 d-flex align-items-center" :class="ads.status">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Atividade diária
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="this.ads.url">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="this.ads.url" v-on:click="$toast.success('Copiado!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
        <div class="mt-1 ml-2 flex-fill text-right">
          <div v-if="ads.status == 'pending'">
            <i class="bx bxs-timer font-size-22 text-danger"></i>
          </div>
          <div v-else-if="ads.status == 'approved'">
            <i class="bx bxs-check-square font-size-24 text-success"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Indique e ganhe
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="'https://app.anunciapro.com/' + account.user.username">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="'https://app.anunciapro.com/' + account.user.username
            " v-on:click="$toast.success('O link foi copiado!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="d-block d-sm-none mb-4">
      <router-link tag="a" to="/stakes" class="btn btn-default btn-bet w-100 py-3">
        <img class="stake-cover" :src="require('@/assets/images/stakes/black.png')" />
        <span class="btn-title ml-2">Minhas Stakes</span>
      </router-link>
    </div>

    <div class="d-block d-sm-none mb-4">
      <router-link tag="a" to="/stakes/create" class="btn btn-default btn-bet w-100 py-3">
        <img class="stake-cover" :src="require('@/assets/images/stakes/black.png')" />
        <span class="btn-title ml-2">Comprar Stakes</span>
      </router-link>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.ads.pending {
  border: solid 2px #ff0000;
  border-radius: 3px;
  background: #ff000020;
}

.ads.approved {
  border: solid 2px #34C392;
  border-radius: 3px;
  background: #34C39220;
}
</style>